import { createSelector } from "reselect";

const getId = (_, id) => id;
const getLessonsUsers = (state) => state.entities.lessonsUsers;
export const getIds = (_, ids) => ids;

export const getAllLessonsUsers = createSelector(
  getLessonsUsers,
  (lessonsUsers) => Object.values(lessonsUsers)
);

export const getLessonUserByLesson = createSelector(
  getAllLessonsUsers,
  getId,
  (lessonsUsers, id) =>
    lessonsUsers.filter((lessonUser) => lessonUser.lessonId == id)[0]
);
