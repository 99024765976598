import { apiGet, apiPost, apiPut } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { fetchClassroom } from "./classroomProcesses";
import toQueryString from "../utils/toQueryString";

const lessonSchema = new schema.Entity("lessons");
const lessonUserSchema = new schema.Entity("lessonsUsers");

export const fetchLessons = (dispatch, classroomId) => {
  dispatch({ type: "LESSONS_FETCH_REQUESTED" });
  apiGet(`/api/v1/classrooms/${classroomId}/lessons`).then((response) => {
    dispatch({
      type: "LESSONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(lessonSchema)),
    });
  });
};

export const fetchLessonsUsers = (dispatch, classroomId) => {
  dispatch({ type: "LESSONS_USERS_FETCH_REQUESTED" });
  apiGet(`/api/v1/classrooms/${classroomId}/lessons_users`).then((response) => {
    dispatch({
      type: "LESSONS_USERS_FETCHED",
      ...normalize(response.body.data, new schema.Array(lessonUserSchema)),
    });
  });
};

export const markAsWatched = (
  progress = 1,
  dispatch,
  classroomId,
  lessonId
) => {
  return apiPost(
    `/api/v1/classrooms/${classroomId}/lessons/${lessonId}/lessons_users`
  )
    .send({ lessonId, progress })
    .then((response) => {
      dispatch({
        type: "LESSON_WATCHED",
        ...normalize({ ...response.body.data }, lessonUserSchema),
      });
      fetchClassroom(dispatch, classroomId);
    })
    .catch(
      (err) =>
        new Error(`Error on create lesson user: ${err.response.body.errors}`)
    );
};

export const updateWatchProgress = (
  progress,
  lessonUserId,
  dispatch,
  classroomId,
  lessonId
) => {
  return apiPut(
    `/api/v1/classrooms/${classroomId}/lessons/${lessonId}/lessons_users/${lessonUserId}`
  )
    .send({ progress })
    .then((response) => {
      dispatch({
        type: "LESSONS_USERS_UPDATED",
        ...normalize({ ...response.body.data }, lessonUserSchema),
      });
      fetchClassroom(dispatch, classroomId);
    })
    .catch(
      (err) =>
        new Error(`Error on update lesson user: ${err.response.body.errors}`)
    );
};

export const submitSearchFilter = (values, dispatch, { classroomId }) => {
  dispatch({ type: "CLASSROOM_LESSON_SEARCH_FETCH_REQUEST" });
  const searchParams = toQueryString(values);
  apiGet(`/api/v1/classrooms/${classroomId}/lessons?${searchParams}`).then(
    (response) => {
      dispatch({
        type: "LESSONS_FETCHED",
        ...normalize(response.body.data, new schema.Array(lessonSchema)),
      });
      dispatch({
        type: "CLASSROOM_LESSON_SEARCH_FETCHED",
        ...normalize(response.body.data, new schema.Array(lessonSchema)),
      });
    }
  );
};
