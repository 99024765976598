const getLessons = (action) => action.entities.lessons;

export default function lessonsReducer(state = {}, action) {
  switch (action.type) {
    case "LESSONS_FETCHED":
      return { ...getLessons(action) };
    default:
      return state;
  }
}
