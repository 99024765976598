import React, { useState, useCallback } from "react";
import useEventListener from "../../utils/useEventListener";

const useMessageHandler = (eventName, callback) => {
  const messageHandler = useCallback(
    (event) => {
      if (event.data.message === eventName) callback(event);
    },
    [eventName, callback]
  );
  useEventListener("message", messageHandler);
};

export default function LessonEmbed({
  lesson,
  lessonUser,
  createProgressHandler,
  updateWatchProgressHandler,
}) {
  const lessonProgress = lessonUser?.progress;
  const [triggeredMilestones, setTriggeredMilestones] = useState([]);

  const milestoneHandler = useCallback(
    (milestone) => {
      if (!!lessonUser && milestone > lessonProgress) {
        updateWatchProgressHandler({
          progress: milestone,
          lessonUserId: lessonUser.id,
        });
      }
    },
    [updateWatchProgressHandler, lessonProgress, lessonUser]
  );

  const pandaAllDataHandler = useCallback(
    (event) => {
      const { currentTime, duration } = event.data.playerData;
      const progress = duration ? currentTime / duration : 0;
      const milestones = [0.25, 0.5, 0.75, 0.95, 1];

      milestones.forEach((milestone) => {
        if (progress >= milestone && !triggeredMilestones.includes(milestone)) {
          setTriggeredMilestones((prev) => [...prev, milestone]);
          milestoneHandler(milestone);
        }
      });
    },
    [triggeredMilestones, milestoneHandler]
  );

  const pandaReadyHandler = useCallback(() => {
    if (!lessonUser) {
      createProgressHandler({ progress: 0.01 });
    }
  }, [lessonUser]);

  useMessageHandler("panda_ready", pandaReadyHandler);
  useMessageHandler("panda_allData", pandaAllDataHandler);

  return <div dangerouslySetInnerHTML={{ __html: lesson.embed }} />;
}
