import React from "react";
import { css } from "glamor";
import Link from "../library/Link";
import {
  colors,
  spacing,
  styles,
  typographyColors,
  uiColors,
} from "../library/theme";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdRadioButtonUnchecked } from "react-icons/md";

const container = css(styles.transition, styles.roundedCorners, {
  display: "flex",
  flex: 1,
  padding: `${spacing.s} ${spacing.s}`,
  cursor: "pointer",
  justifyContent: "space-between",
  "& + &": {
    borderTop: `1px solid ${uiColors.backgroundDark}20`,
  },
  ":hover": {
    backgroundColor: `${uiColors.backgroundDark}20`,
  },
});
const activeStyle = css({
  backgroundColor: uiColors.backgroundDark,
  color: `${typographyColors.inverseBody} !important`,
  ":hover": {
    backgroundColor: uiColors.backgroundDark,
  },
});
const disabledStyle = css({
  pointerEvents: "none",
  "> div > p, > div > svg": {
    color: colors.grey,
  },
});
const contentWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});
const textWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
});
const iconWrapper = {
  marginLeft: spacing.s,
  display: "flex",
  alignItems: "center",
};

export default function LessonListItem(props) {
  const { lesson, lessonUser, currentClassroomId, compact, compliant } = props;
  const lessonPath = `/classrooms/${currentClassroomId}/lessons/${lesson.id}`;
  const active = props.currentLessonId === lesson.id;
  const disabled = !lessonUser?.watched && !compliant;

  return (
    <Link
      to={lessonPath}
      className={css(
        container,
        active && activeStyle,
        disabled && disabledStyle
      )}
      onClick={props.hideLeftSidebarHandler}
    >
      <div className={contentWrapper}>
        <div className={!compact ? textWrapper : undefined}>
          <p
            className={css(
              active && { color: typographyColors.inverseBody },
              compact && styles.bodyTextSmall
            )}
          >
            {lesson.name}
          </p>
        </div>
        <div style={iconWrapper}>
          {!!lessonUser?.watched ? (
            <AiFillCheckCircle size={22} />
          ) : (
            <MdRadioButtonUnchecked size={22} />
          )}
        </div>
      </div>
    </Link>
  );
}
