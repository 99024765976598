import React from "react";
import { connect } from "react-redux";
import LessonListItem from "./LessonListItem";
import { getLessonUserByLesson } from "../../selectors/lessonUserSelectors";

function LessonListItemContainer(props) {
  return <LessonListItem {...props} />;
}

function mapStateToProps(state, ownProps) {
  const lesson = ownProps.lesson;

  return {
    lesson,
    lessonUser: getLessonUserByLesson(state, lesson.id),
    currentClassroomId: state.currentClassroomId,
    currentLessonId: state.currentLessonId,
  };
}

export default connect(mapStateToProps)(LessonListItemContainer);
