import { css } from "glamor";
import React from "react";
import { Table } from "semantic-ui-react";
import { Page } from "../library";
import { colors, spacing } from "../library/theme";
import CertificateProgress from "./CertificateProgress";
import {
  MAIN_COURSE_ID,
  CERTIFICATE_MINIMUM_DAYS,
} from "../../utils/constants";
import { makeIsMobile } from "../../utils/useScreenWidth";

const contentContainer = css({
  borderRadius: "10px",
  padding: spacing.m,
  background: colors.white,
  "> table": {
    border: "none !important",
    boxShadow: "none !important",
  },
});
const emptyText = {
  padding: spacing.xxl,
  textAlign: "center",
};
const mobileLinkClass = css({
  color: colors.primary,
  display: "flex",
  justifyContent: "end",
});
const linkClass = css(mobileLinkClass, {
  paddingRight: spacing.m,
});
const disabledLink = {
  color: colors.greyLight,
  cursor: "normal",
};
const hintStyle = {
  fontSize: "10px",
};

const tableHeaderCell = css({
  backgroundColor: "#EDEDED !important",
  borderBottom: "none !important",
  ":first-child": {
    borderTopLeftRadius: `${spacing.xxs} !important`,
    borderBottomLeftRadius: `${spacing.xxs} !important`,
  },
  ":last-child": {
    borderTopRightRadius: `${spacing.xxs} !important`,
    borderBottomRightRadius: `${spacing.xxs} !important`,
  },
}).toString();
const tableCell = css({
  border: "none !important",
  ":first-child": {
    borderTopLeftRadius: `${spacing.xxs} !important`,
    borderBottomLeftRadius: `${spacing.xxs} !important`,
  },
  ":last-child": {
    borderTopRightRadius: `${spacing.xxs} !important`,
    borderBottomRightRadius: `${spacing.xxs} !important`,
  },
}).toString();
const mobileDownloadClass = css({
  display: "flex",
  justifyContent: "space-between",
}).toString();

export default function Certificate(props) {
  const { classrooms, userLegalAge } = props;
  const isMobile = makeIsMobile();

  return (
    <Page title="Meus Certificados">
      {classrooms?.length ? (
        <div className={contentContainer}>
          <Table striped={!isMobile}>
            {!isMobile && (
              <>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className={tableHeaderCell}>
                      Curso
                    </Table.HeaderCell>
                    <Table.HeaderCell className={tableHeaderCell}>
                      Turma
                    </Table.HeaderCell>
                    <Table.HeaderCell className={tableHeaderCell}>
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell className={tableHeaderCell}>
                      Progresso
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className={tableHeaderCell}
                    ></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
              </>
            )}

            <Table.Body>
              {classrooms.map((classroom, index) => {
                const { progress, certificateGenerationDate } = classroom?.meta;
                const courseId = classroom?.courseId;
                const enrollmentCreatedAtDays =
                  classroom?.enrollmentCreatedAtDays;

                const isPastMinimumDays =
                  courseId != MAIN_COURSE_ID ||
                  (courseId == MAIN_COURSE_ID &&
                    (enrollmentCreatedAtDays >= CERTIFICATE_MINIMUM_DAYS ||
                      enrollmentCreatedAtDays == null));
                const remainingDaysForCertificate =
                  Number(CERTIFICATE_MINIMUM_DAYS) -
                  Number(enrollmentCreatedAtDays);
                const daysString =
                  remainingDaysForCertificate === 1
                    ? `Falta ${remainingDaysForCertificate} dia`
                    : `Faltam ${remainingDaysForCertificate} dias`;
                const minimumDaysMessage = `${daysString} para o certificado ficar disponível`;

                const unavailableCertificateStatus =
                  (!!certificateGenerationDate || progress >= 1) &&
                  !isPastMinimumDays
                    ? minimumDaysMessage
                    : "Download disponível quando você concluir todo o curso";

                return (
                  <Table.Row key={index}>
                    <Table.Cell className={tableCell}>
                      {classroom.courseName}
                    </Table.Cell>
                    <Table.Cell className={tableCell}>
                      {classroom.name}
                    </Table.Cell>
                    {!isMobile && (
                      <Table.Cell className={tableCell}>
                        {isPastMinimumDays &&
                        (certificateGenerationDate || progress >= 1)
                          ? "Disponível"
                          : unavailableCertificateStatus}
                      </Table.Cell>
                    )}
                    <Table.Cell className={tableCell}>
                      <CertificateProgress
                        separator="/"
                        {...classroom?.meta}
                        showCounters={false}
                      />
                    </Table.Cell>
                    {!isMobile && (
                      <Table.Cell className={tableCell}>
                        <div>
                          {(!!certificateGenerationDate || progress >= 1) &&
                          classroom?.hasCertificate &&
                          userLegalAge &&
                          isPastMinimumDays ? (
                            <a
                              href={`/classrooms/${classroom?.id}/certificates`}
                              target="_blank"
                              className={linkClass}
                            >
                              Download
                            </a>
                          ) : (
                            <p className={css(linkClass, disabledLink)}>
                              Download
                            </p>
                          )}
                        </div>
                      </Table.Cell>
                    )}
                    {isMobile && (
                      <Table.Cell className={tableCell}>
                        <div className={mobileDownloadClass}>
                          <p style={hintStyle}>
                            {isPastMinimumDays &&
                            (certificateGenerationDate || progress >= 1)
                              ? "Disponível"
                              : unavailableCertificateStatus}
                          </p>
                          {(!!certificateGenerationDate || progress >= 1) &&
                          classroom?.hasCertificate &&
                          userLegalAge &&
                          isPastMinimumDays ? (
                            <a
                              href={`/classrooms/${classroom?.id}/certificates`}
                              target="_blank"
                              className={mobileLinkClass}
                            >
                              Download
                            </a>
                          ) : (
                            <p className={css(mobileLinkClass, disabledLink)}>
                              Download
                            </p>
                          )}
                        </div>
                      </Table.Cell>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      ) : (
        <p style={emptyText}>Você ainda não tem certificados</p>
      )}
    </Page>
  );
}
