const getLessonsUsers = (action) => action.entities.lessonsUsers;

export default function lessonsUsersReducer(state = {}, action) {
  switch (action.type) {
    case "LESSONS_USERS_FETCHED":
    case "LESSONS_USERS_UPDATED":
    case "LESSON_WATCHED":
      return { ...state, ...getLessonsUsers(action) };
    default:
      return state;
  }
}
