import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Lesson from "./Lesson";
import {
  getCurrentLesson,
  getNextLesson,
  getPrevLesson,
  blockContent,
  isPreviousLessonsWatched,
  isLessonUnlocked,
} from "../../selectors/lessonSelectors";
import {
  markAsWatched,
  updateWatchProgress,
} from "../../processes/lessonProcesses";
import { canRateLesson } from "../../selectors/ratingSelectors";
import { createLessonRating } from "../../processes/ratingProcesses";
import { getCurrentClassroom } from "../../selectors/classroomSelectors";
import { fetchClassroom } from "../../processes/classroomProcesses";
import { apoloProcess } from "../../processes/apoloProcesses";
import {
  getCurrentUser,
  isUserCbtrgValid,
} from "../../selectors/userSelectors";
import { getLessonUserByLesson } from "../../selectors/lessonUserSelectors";

function LessonContainer(props) {
  const {
    dispatch,
    lesson,
    lessonUser,
    currentClassroomId,
    isWatched,
    currentUser,
  } = props;

  const onProgressHandler = useCallback(
    ({ played }) =>
      !isWatched &&
      played > 0.95 &&
      markAsWatched(played, dispatch, currentClassroomId, lesson.id),
    [dispatch, lesson, currentClassroomId]
  );

  const markAsWatchedHandler = useCallback(
    ({ progress }) =>
      markAsWatched(progress, dispatch, currentClassroomId, lesson.id),
    [lesson, currentClassroomId, dispatch]
  );

  const updateWatchProgressHandler = useCallback(
    ({ progress, lessonUserId }) =>
      updateWatchProgress(
        progress,
        lessonUserId,
        dispatch,
        currentClassroomId,
        lesson?.id
      ),
    [lesson, currentClassroomId, dispatch]
  );

  useEffect(() => {
    lesson &&
      apoloProcess({ email: currentUser?.email }).then(() => {
        fetchClassroom(dispatch, currentClassroomId);
      });
    document.documentElement.scrollTop = 0;
  }, [dispatch, currentUser?.email, lesson, currentClassroomId]);

  const lessonRatingHandler = useCallback(
    (_, { rating }) => {
      createLessonRating(dispatch, lesson?.id, rating);
    },
    [dispatch, lesson]
  );

  const watchByProgress = lesson?.lessonType == "video" && !!lesson?.embed;

  return (
    <Lesson
      markAsWatchedHandler={markAsWatchedHandler}
      updateWatchProgressHandler={updateWatchProgressHandler}
      onProgressHandler={onProgressHandler}
      lessonRatingHandler={lessonRatingHandler}
      watchByProgress={watchByProgress}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps.match.params;
  const lesson = getCurrentLesson(state, id);
  const lessonUser = getLessonUserByLesson(state, id);
  const currentClassroom = getCurrentClassroom(state);
  const enrollmentCreatedAtDays = currentClassroom?.enrollmentCreatedAtDays;
  const compliant = currentClassroom?.meta?.compliant;
  const hasEnrollment = currentClassroom?.meta?.hasEnrollment;
  const isCbtrg = currentClassroom?.meta?.isCbtrg;
  const isUnlocked = currentClassroom?.meta?.isUnlocked;
  const daysToUnlock = lesson?.daysToUnlock
    ? lesson?.daysToUnlock - enrollmentCreatedAtDays
    : 0;

  return {
    lesson,
    lessonUser,
    compliant,
    enrollmentCreatedAtDays,
    hasEnrollment,
    isCbtrg,
    daysToUnlock,
    currentClassroom,
    isUnlocked,
    isUserCbtrgValid: isUserCbtrgValid(state),
    currentClassroomId: state.currentClassroomId,
    currentUser: getCurrentUser(state),
    isWatched: lessonUser && lessonUser?.watched,
    canRate: canRateLesson(state, lesson?.id),
    prevLesson: getPrevLesson(state),
    nextLesson: getNextLesson(state),
    blockContent: lesson?.core
      ? blockContent(state, lesson?.id)
      : !isLessonUnlocked(state, lesson?.id),
    watchedPrevious: isPreviousLessonsWatched(state, lesson?.id),
  };
}

export default connect(mapStateToProps)(LessonContainer);
