import React from "react";
import { css } from "glamor";
import { styles } from "../library/theme";
import Video from "../library/Video";
import LessonEmbed from "./LessonEmbed";

const videoWrapper = css(styles.roundedCorners, {
  position: "relative",
  paddingBottom: "56.25%",
  width: "100%",
});
const videoClass = css(styles.roundedCorners, {
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
});
const swarmifyStyle = {
  height: "100%",
  width: "100%",
};

const parseVimeoUrl = (url) => {
  if (!url) return;
  let vimeoUrl;
  const parsedURL = new URL(url);
  if (parsedURL?.hostname === "player.vimeo.com") {
    const videoId = parsedURL.pathname.replace("/external/", "").split(".")[0];
    vimeoUrl = `https://vimeo.com/${videoId}`;
  }
  return vimeoUrl ? vimeoUrl : url;
};

export default function LessonVideo(props) {
  const { lesson, markAsWatchedHandler, onProgressHandler } = props;
  const enabledSwarmify = !!lesson.swarmifyUrl;

  if (lesson.embed) {
    return (
      <LessonEmbed createProgressHandler={markAsWatchedHandler} {...props} />
    );
  }

  if (enabledSwarmify) {
    return (
      <div className={videoWrapper}>
        <div className={videoClass}>
          <div
            id="main_video"
            src={lesson.swarmifyUrl}
            className="smartvideo"
            style={swarmifyStyle}
            controls
          />
        </div>
      </div>
    );
  }

  return (
    <Video
      url={parseVimeoUrl(lesson.videoUrl)}
      onProgressHandler={onProgressHandler}
      progress={lesson.progress}
    />
  );
}
