import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import annotationsReducer from "./annotationsReducer";
import attachmentsReducer from "./attachmentsReducer";
import bannersReducer from "./bannersReducer";
import classroomsContractsReducer from "./classroomsContractsReducer";
import classroomsListReducer from "./classroomsListReducer";
import classroomsReducer from "./classroomsReducer";
import commentsReducer from "./commentsReducer";
import communitiesReducer from "./communitiesReducer";
import configsReducer from "./configsReducer";
import currentClassroomReducer from "./currentClassroomReducer";
import currentLessonReducer from "./currentLessonReducer";
import currentUserReducer from "./currentUserReducer";
import faqsReducer from "./faqsReducer";
import invoicesReducer from "./invoicesReducer";
import lessonsReducer from "./lessonsReducer";
import levelGroupsReducer from "./levelGroupsReducer";
import levelsReducer from "./levelsReducer";
import modalsReducer from "./modalsReducer";
import notifier from "./notifierReducer";
import postsReducer from "./postsReducer";
import postCategoriesReducer from "./postCategoriesReducer";
import ratingsReducer from "./ratingsReducer";
import surveyUtilsReducer from "./surveyUtilsReducer";
import surveysUsersReducer from "./surveysUsersReducer";
import termsReducer from "./termsReducer";
import usersReducer from "./usersReducer";
import viewsReducer from "./viewsReducer";
import likesReducer from "./likesReducer";
import postComplaintsReducer from "./postComplaintsReducer";
import lessonsListReducer from "./lessonsListReducer";
import levelsListReducer from "./levelsListReducer";
import lessonsUsersReducer from "./lessonsUsersReducer";

export default (history) =>
  combineReducers({
    entities: combineReducers({
      annotations: annotationsReducer,
      attachments: attachmentsReducer,
      banners: bannersReducer,
      classrooms: classroomsReducer,
      communities: communitiesReducer,
      comments: commentsReducer,
      configs: configsReducer,
      levelGroups: levelGroupsReducer,
      levels: levelsReducer,
      lessons: lessonsReducer,
      lessonsUsers: lessonsUsersReducer,
      modals: modalsReducer,
      ratings: ratingsReducer,
      posts: postsReducer,
      postCategories: postCategoriesReducer,
      postComplaints: postComplaintsReducer,
      likes: likesReducer,
      users: usersReducer,
      terms: termsReducer,
      invoices: invoicesReducer,
      views: viewsReducer,
      faqs: faqsReducer,
      surveysUsers: surveysUsersReducer,
      classroomsContracts: classroomsContractsReducer,
    }),
    surveyUtils: surveyUtilsReducer,
    currentUserId: currentUserReducer,
    currentClassroomId: currentClassroomReducer,
    currentLessonId: currentLessonReducer,
    router: connectRouter(history),
    classroomsList: classroomsListReducer,
    lessonsList: lessonsListReducer,
    levelsList: levelsListReducer,
    form,
    notifier,
  });
